<template>
<div class="ddd">
    <DddCover />
    <div class="container mt-5 px-2">
        <h3>ДДД Услуги</h3>
        <p>
            <strong>Дезинсекция</strong> срещу  битови и складови вредители, преносители на заболявания и имащи епидемиологично значение. <br><br>
            <strong>Дезинфекция</strong> - предлагаме извършването на ултрамалообемна дезинфекция на вътрешни площи посредством електрически УЛВ генератор със студен аерозол <br><br>
            <strong>Дератизация</strong> – комплексни мероприятия за унищожаване на гризачи 
            / мишки и плъхове / <br><br>
            <strong>Контрол на вредители в жилища и офиси</strong><br>
            Специалисти от фирмата извършват еднократни обработки по домовете и офисите срещу срещу хлебарки, бълхи, дървеници, мухи и гризачи. Ниската токсичност на препаратите, които използваме прави обработката безопасна за Вас, Вашите деца и домашни любимци.
            <br><br>
            <strong>Контрол на вредителите в заведения</strong><br>
            Фирмата сключва договори за абонаментно третиране срещу всички пълзящи, летящи насекоми и гризачи на кухненски блокове в ресторанти, заведения за бързо хранене, пицарии и др.
            <br><br>
            <strong>Контрол на вредителите в предприятия и обществени сгради</strong><br>
            На базата на предлаганата обширна гама препарати и висок професионализъм нашата фирма е надежден партньор в борбата срещу вредните насекоми и гризачи.  Извършваме и консултанска дейност в различни производства за предприемане на корективни мерки, с цел недопускани навлизането и развитието на вредители.
        </p>
    </div>
    <EmailContact />
</div>
</template>

<script>
    import DddCover from "./includes/DddCover"
    import EmailContact from '../contacts/EmailContact'

    export default {
        name: "Ddd",
        components: {
            DddCover,
            EmailContact
        }
    }
</script>