<template>
    <div class="ddd-cover cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <h4 class="text-white mx-5 mt-5 mb-4">Дезинфекция, Дезинсекция и Дератизация (ДДД)</h4>
                    <p class="text-white mx-5 my-4">
                        Специализирали сме в обслужването на високо технологични и  рискови обекти, изискващи непрекъсваемост на производствения или търговски процес, както и дейности с методи и средства, изключващи всякакви предпоставки за замърсяване на работни и спомагателни помещения с химически вещества представляващи риск за човешкото здраве, хранителни продукти  и околната среда.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "DddCover"
    }
</script>